
import axios from '../utils/request';
const baseShout={
    bb:'https://depot.careld.cn',

    //(库管系统)h5销售分组
    getGroups:'too/center/salesgroup/getGroup',//查询销售分组字典
    getGroupAsc:'too/center/salesgroup/getGroupAsc',//倒序-查询销售分组字典
    updateGroups:'too/center/salesgroup/changeEquipmentGroup',//修改设备销售分组状态
    updateBindGroup:'too/center/salesgroup/changeBindEquipmentGroup',//h5处理绑定数据
    repeatEquipmentRecord:'too/center/salesgroup/repeatEquipmentRecord',//录入imei绑定设备
    repeatEquipment:'/too/center/salesgroup/repeatEquipment',//录入imei立即生效
    recoveryEquipment:'/too/center/salesgroup/recoveryEquipment',//恢复删除设备
}
//请求方法
export const apiShout={
    //h5查询销售分组字典
    getGroups(params){
        return axios.post(baseShout.getGroups,params)
    },
    //h5倒序-查询销售分组字典
    getGroupAsc(params){
        return axios.post(baseShout.getGroupAsc,params)
    },
    //h5修改设备销售分组状态
    updateGroup(params){
        return axios.post(baseShout.updateGroups,params)
    },
    //h5处理绑定数据
    updateBindGroups(params){
        return axios.post(baseShout.updateBindGroup,params)
    },
    //录入imei绑定设备
    repeatEquipmentRecord(params){
        return axios.post(baseShout.repeatEquipmentRecord,params)
    },
    //录入imei立即生效
    repeatEquipment(params){
        return axios.post(baseShout.repeatEquipment,params)
    },
    //恢复删除设备
    recoveryEquipment(params){
        return axios.post(baseShout.recoveryEquipment,params)
    },
}
export default baseShout